import React, { useState, useRef } from "react";
import "../paypal/paypal.css";
import axios from "axios";
import phonepe from "../../image/phonepe.jpg";

const Phonepe = () => {
  const [loading2, setLoading2] = useState(false);

  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const amountRef = useRef(null);

  const handlePayment = (e) => {
    e.preventDefault();
    setLoading2(true);
    const data = {
      name: nameRef.current.value,
      amount: amountRef.current.value,
      number: phoneRef.current.value,
      MUID: "MUID" + Date.now(),
      transactionId: "PULSE" + Date.now(),
    };
    axios
      .post("http://127.0.0.1:5000/api/payment", { ...data })
      .then((res) => {
        setTimeout(() => {
          setLoading2(false);
        }, 1500);
      })
      .catch((error) => {
        setLoading2(false);
        console.error(error);
      });
  };
  return (
    <>
      <div className="main">
        <div
          style={{ backgroundColor: "black" }}
          className="card px-5 py-3 mt-5 border-0"
        >
          <h2
            className="px-3 py-3 border-0 rounded-1"
            style={{ backgroundColor: "black" }}
          >
            <div>
              <span>PULSE </span>
              <span style={{ color: "orange" }}>BROADBAND</span>
            </div>
            <div className="center">
              <small className="form-text text-muted fs-6 mb-2">
                Recharge Portal
              </small>
            </div>
          </h2>
          <form onSubmit={handlePayment}>
            <div className="form-group mt-3">
              <label>Name:</label>
              <input
                className="form-control"
                type="text"
                ref={nameRef}
                required
              />
            </div>
            <div className="col-12 mt-4">
              <label>Phone Number:</label>
              <input
                className="form-control"
                type="tel"
                ref={phoneRef}
                required
              />
            </div>
            <div className="col-12 mt-4 mb-5">
              <label>Amount:</label>
              <input
                className="form-control"
                type="number"
                ref={amountRef}
                required
              />
            </div>
            {!loading2 ? (
              <div className="col-12 center">
                <button
                  className="w-100 btn btn-warning fw-bolder fs-6"
                  type="submit"
                >
                  Pay Now
                </button>
              </div>
            ) : (
              <div className="col-12 center">
                <button
                  className="w-100 text-center btn btn-warning"
                  type="submit"
                >
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden ">Wait...</span>
                  </div>
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Phonepe;
